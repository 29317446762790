import React, { useEffect, useState, Fragment } from "react"
import Papa from "papaparse"

export default function useGoogleSheets(csv_url) {
  const [data, setData] = useState([])

  useEffect(() => {
    Papa.parse(csv_url, {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: results => {
        console.log(results)
        setData(results.data)
      },
    })
  }, [])

  return data
}
