import React, { useEffect } from "react"
import { useRef } from "react"
import styled from "@emotion/styled"
import useGoogleSheets from "../hooks/useGoogleSheets"

// Hacky TimelineJS constants
const GSHEETS_CSV_URL =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vT36qO8S0qzRUjFirPGL_FCytfK-RuWes5vAaY5zS7iURYhzsoSi3GeZ0Ju1Fb7-6IDOn3-cd4SCqjN/pub?output=csv"

const Container = styled.div`
  min-height: 100vh;
  max-width: 100vw;
`

export default function TimelineComponent() {
  const data = useGoogleSheets(GSHEETS_CSV_URL)
  const timeline = useRef(null)
  const timelineEl = useRef(null)
  const options = {
    debug: true,
  }
  const events = data
    .filter(row => {
      return row.Year && row.Media
    })
    .map(row => ({
      start_date: {
        year: row.Year,
        month: row.Month ?? undefined,
      },
      end_date: row["End Year"]
        ? {
            year: row["End Year"],
            month: row["End Month"] ?? undefined,
          }
        : undefined,
      text: {
        headline: row.Name,
        text: row.Description,
      },
      media: row.Media
        ? {
            url: row.Media,
          }
        : undefined,
    }))
    .filter(row => row.media.url)

  useEffect(() => {
    if (events.length !== 0) {
      console.log(events)
      timeline.current = new window.TL.Timeline(
        timelineEl.current,
        { events },
        options
      )
    }
  }, [events, options])

  return <Container ref={timelineEl} />
}
