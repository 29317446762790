import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "normalize.css"

export default function Layout(props) {
  return (
    <>
      <Header />
      <main {...props} />
      <Footer />
    </>
  )
}
