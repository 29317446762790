import React from "react"
import Timeline from "../components/Timeline"
import Layout from "../components/layout/Layout"
import { css } from "@emotion/core"

const title = css`
  text-align: center;
  text-transform: uppercase;
`

export default function Home() {
  return (
    <Layout>
      <h1 css={title}>European Art in the Middle Ages</h1>
      <Timeline />
    </Layout>
  )
}
